import { visaTypeToPath, VISAVALUE } from "../types/tables-data";

const getShortString = (arg: string | undefined, limit = 20) => {
  if (arg) {
    if (arg.length > 20) return arg.substring(0, limit).concat("...");
    return arg;
  }
  return "";
};

const isValidVisaKey = (
  visa: any
): visa is Exclude<VISAVALUE, VISAVALUE.EMPTY> => {
  return visa in visaTypeToPath;
};

export { getShortString, isValidVisaKey };
