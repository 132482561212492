import generateCaseSummary from "./images/generateCaseSummary.svg";
import uploadDocument from "./images/uploadDocument.svg";
import draftLetters from "./images/draftLetters.svg";
import archive from "./images/archive.svg";
import deleteCase from "./images/deleteCase.svg";
import emailClient from "./images/emailClient.svg";
import schedule from "./images/schedule.svg";
import logoRobot from "./images/logo1.svg";
import aiInfo from "./images/aiInfo.svg";
import extractionInfo from "./images/extractionInfo.svg";
import draftingInfo from "./images/draftingInfo.svg";
import reviewInfo from "./images/reviewInfo.svg";
import reviewNew from "./images/reviewNew.svg";
import translateDoc from "./images/translateDoc.svg";
import bgLanding from "./images/bgLanding.png";
import bgLanding2 from "./images/bgLanding2.png";
import crmLogo from "./images/crmLogo.png";
import dbLogo from "./images/dbLogo.png";
import folders from "./images/folders.png";
import gmail from "./images/gmail.png";
import outlook from "./images/outlook.png";
import gCalendar from "./images/gCalendar.png";
import zoom from "./images/zoom.png";
import joinPageWawes from "./images/joinPageWawes.png";
import linkedIn from "./images/linkedIn.png";
import youtube from "./images/youtube.png";
import aboutPageWawes from "./images/aboutPageWawes.png";
import khalilPhoto2 from "./images/khalilPhoto2.png";
import simonPhoto2 from "./images/simonPhoto2.png";
import agnesPhoto2 from "./images/agnesPhoto2.png";
import immigration from "./images/immigration.png";
import estatePlanning from "./images/estatePlanning.png";
import family from "./images/family.png";
import caseBlinkWhiteIcon from "./logos/CaseBlink-white-icon.svg";
import resumeSvg from "./svgs/resume.svg";
import squarePattern from "./svgs/squarepattern.svg";
import frame from "./svgs/Frame.svg";
import premiumIcon from "./svgs/premium-icon.svg";
import crownIcon from "./svgs/Crown.svg";
import fileupload from "./svgs/fileupload.svg";
import fileupload2 from "./svgs/fileupload2.svg";
import uploadCloud from "./svgs/UploadCloud.svg";
import aiGenerate from "./svgs/aiGenerate.svg";
import cases from "./svgs/sidebar/cases.svg";
import tools from "./svgs/sidebar/tools.svg";
import studio from "./svgs/sidebar/studio.svg";
import newCaseBlinkLogo from "./logos/CaseBlink-full-logoNew.svg";

const images = {
  caseBlinkWhiteIcon,
  generateCaseSummary,
  uploadDocument,
  draftLetters,
  archive,
  deleteCase,
  emailClient,
  schedule,
  logoRobot,
  aiInfo,
  extractionInfo,
  draftingInfo,
  reviewInfo,
  bgLanding,
  bgLanding2,
  crmLogo,
  dbLogo,
  folders,
  gmail,
  outlook,
  gCalendar,
  zoom,
  translateDoc,
  joinPageWawes,
  linkedIn,
  youtube,
  aboutPageWawes,
  khalilPhoto2,
  agnesPhoto2,
  simonPhoto2,
  immigration,
  estatePlanning,
  family,
  reviewNew,
  resumeSvg,
  squarePattern,
  frame,
  premiumIcon,
  crownIcon,
  fileupload,
  fileupload2,
  uploadCloud,
  newCaseBlinkLogo,
  aiGenerate,
  cases,
  tools,
  studio
};

export default images;
