import React from "react";
import {
  Box,
  Checkbox,
  Input,
  FormControl,
  FormLabel,
  VStack,
  Text,
} from "@chakra-ui/react";

interface DatePickerValue {
  date: string; // Either startDate or endDate
  isMonthYearFormat: boolean;
}

interface Props {
  value: DatePickerValue; // Represents a single date with format
  onChange: (updatedValue: DatePickerValue) => void; // Single setter for the date and format
}

const DatePickerWithCheckbox: React.FC<Props> = ({ value, onChange }) => {
  const { date, isMonthYearFormat } = value;

  const handleCheckboxChange = (isChecked: boolean) => {
    onChange({ ...value, isMonthYearFormat: isChecked }); // Update format in parent
  };

  const handleDateChange = (newDate: string) => {
    onChange({ ...value, date: newDate }); // Update date in parent
  };

  return (
    <Box>
      <VStack spacing={4} align="stretch">
        <FormControl>
          <Checkbox
            isChecked={isMonthYearFormat}
            onChange={(e) => handleCheckboxChange(e.target.checked)}
          >
            <Text fontSize="md" fontWeight="medium">
              Use Month and Year Format
            </Text>
          </Checkbox>
        </FormControl>

        <FormControl>
          <FormLabel fontSize="sm" fontWeight="medium">
            Select Date
          </FormLabel>
          <Input
            type={isMonthYearFormat ? "month" : "date"}
            value={date}
            onChange={(e) => handleDateChange(e.target.value)}
            placeholder={isMonthYearFormat ? "YYYY-MM" : "YYYY-MM-DD"}
            size="md"
            borderRadius="md"
          />
        </FormControl>
      </VStack>
    </Box>
  );
};

export default DatePickerWithCheckbox;
