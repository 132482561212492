import {
  collection,
  getDocs,
  onSnapshot,
  query,
  where
} from "firebase/firestore";
import { useEffect } from "react";
import { db } from "../api/firebaseApi";
import {
  addNewDocument,
  deleteDocument,
  setDocuments,
  updateDocument
} from "../redux/documents/documentSlice";
import { useTypedDispatch } from "../redux/store";
import { DataDocs } from "../types/tables-data";
import { VisaDocumentType } from "../redux/documents/types";
import {
  generateDocumentUrls,
  generateSingleDocumentUrl
} from "../redux/documents/documentsThunk";

export const useGetStandardDocs = (collectionPath: string) => {
  const dispatch = useTypedDispatch();

  useEffect(() => {
    const fetchDocuments = async () => {
      const collectionRef = collection(db, collectionPath);

      const filteredQuery = query(
        collectionRef,
        where("isDeleted", "in", [false, null])
      );

      const querySnapshot = await getDocs(filteredQuery);
      const documents = querySnapshot.docs.map(
        (doc) =>
          ({
            id: doc.id,
            docRef: doc.ref.path,
            sub_documents: [] as DataDocs[],
            isDeleted: doc.data().isDeleted ?? false,
            ...doc.data()
          } as DataDocs)
      ); // narrow the type to DataDocs

      dispatch(
        setDocuments({ data: documents, type: VisaDocumentType.Standard })
      );

      dispatch(
        generateDocumentUrls({
          documents: documents.map((doc) => ({
            id: doc.id as string,
            filePath: doc.filePath as string
          })),
          type: VisaDocumentType.Standard
        })
      );
    };

    const unsubscribe = onSnapshot(
      collection(db, collectionPath),
      (snapshot) => {
        snapshot.docChanges().forEach((change) => {
          const document = {
            ...change.doc.data(),
            docRef: change.doc.ref.path,
            id: change.doc.id,
            isDeleted: change.doc.data().isDeleted ?? false
          } as DataDocs;

          if (change.type === "modified") {
            dispatch(
              updateDocument({
                data: document,
                type: VisaDocumentType.Standard
              })
            );
          }
          if (change.type === "added") {
            dispatch(
              addNewDocument({
                data: document,
                type: VisaDocumentType.Standard
              })
            );
            dispatch(
              generateSingleDocumentUrl({
                id: document.id ?? "",
                filePath: document.filePath ?? "",
                type: "standard"
              })
            ); // Dispatch new thunk
          }
          if (change.type === "removed") {
            dispatch(
              deleteDocument({
                data: document,
                type: VisaDocumentType.Standard
              })
            );
          }
        });
      }
    );

    fetchDocuments();

    return () => unsubscribe();
  }, [collectionPath, dispatch]);
};
