import React, { PropsWithChildren } from "react";
import {
  useDisclosure,
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Box,
  Text,
  UnorderedList,
  ListItem,
  Button
} from "@chakra-ui/react";
import { MdInfoOutline } from "react-icons/md";

interface DocumentsInfoModalProps extends PropsWithChildren {
  title: string;
}

export const InputFieldInfoModal = ({
  title,
  children
}: DocumentsInfoModalProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  // Find the content based on the provided title

  return (
    <>
      <IconButton
        variant="filledIconButton"
        onClick={onOpen}
        aria-label="View information"
        icon={<MdInfoOutline size={20} />}
      />

      <Modal isOpen={isOpen} onClose={onClose} size="lg">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textColor="#00004D">{title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>{children}</ModalBody>

          <ModalFooter />
        </ModalContent>
      </Modal>
    </>
  );
};
