import { Divider, VStack } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { visaFromPath, VISAVALUE } from "../../types/tables-data";
import { PacketGenerateSection } from "./individualPacket/PacketGenerateSection";
import { SignedLettersSection } from "./individualPacket/SignedLettersSection";

interface Props {
  mainTabIndex?: number | null;
  subTabIndex?: number | null;
}

export const Packet = ({ mainTabIndex, subTabIndex }: Props) => {
  const { visaType } = useParams();
  const showForCurrentVisa = visaFromPath[visaType || ""] !== VISAVALUE.EB1C;

  return (
    <div className="w-fulll h-full">
      <VStack
        alignItems="stretch"
        gap={8}
        divider={<Divider my={8} />}
        w="100%"
      >
        {showForCurrentVisa && <SignedLettersSection />}

        <PacketGenerateSection />
      </VStack>
    </div>
  );
};
