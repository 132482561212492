import { Divider, VStack } from "@chakra-ui/react";
import { useLocation, useParams } from "react-router-dom";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useExpertLetterDrafts } from "../../hooks/useExpertLetterDrafts";
import { useGetFormsData } from "../../hooks/useGetFormsData";
import { ExhibitListSection } from "./individualDrafts/ExhibitListSection";
import { ExpertLetterDraftsSection } from "./individualDrafts/ExpertLettersDraftSection";
import { SopDraftSection } from "./individualDrafts/SopDraftSection";
import { useGetIndividualData } from "../../hooks/useGetIndividualData";
import { shouldAccess } from "../../helpers/firebase_helpers";
import { visaFromPath, VISAVALUE } from "../../types/tables-data";

interface Props {
  mainTabIndex?: number | null;
  subTabIndex?: number | null;
}

export const IndividualDrafts = ({ mainTabIndex, subTabIndex }: Props) => {
  const { id, visaType} = useParams();
  const loginType = localStorage.getItem("loginType");
  const { onSubmitGetIndividualData, isLoadingGetIndividualData, individualData } = useGetIndividualData();
  const [isLoadingDrafts, setIsLoadingDrafts] = useState(true);

  useEffect(() => {
    if (id) {
      onSubmitGetIndividualData(id);
      setIsLoadingDrafts(false);
    }
  }, [id]);

  useExpertLetterDrafts(id!);
  useGetFormsData();

  const accessControl = useMemo(() => ({
    experts: shouldAccess(individualData, loginType, "drafts.experts"),
    exhibitList: shouldAccess(individualData, loginType, "drafts.exhibit_list"),
    sop: shouldAccess(individualData, loginType, "drafts.sop"),
  }), [individualData, loginType]);


  if (isLoadingDrafts || isLoadingGetIndividualData) {
    return <div>Loading drafts...</div>;
  }

  const showForCurrentVisa = visaFromPath[visaType || ""] !== VISAVALUE.EB1C;

  return (
      <div className="w-full h-full">
          <VStack
            alignItems="stretch"
            gap={8}
            divider={<Divider my={8} />}
            w="100%"
          >
            {accessControl.experts && showForCurrentVisa &&  <ExpertLetterDraftsSection />}
            {/* <CoverLetterDraftsSection /> */}
            {accessControl.exhibitList && <ExhibitListSection />}
            {accessControl.sop && <SopDraftSection />}
          </VStack>
      </div>
    // )
  );
};
