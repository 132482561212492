import {
  Badge,
  Flex,
  Heading,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs
} from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { SectionWrapper } from "../components/common/SectionWrapper";
import { SpecialHeading } from "../components/individualTabs/PanelLayout";
import { ContactInfo } from "../forms/client/ClientContactInformation";
import { ClientEmployerInformation } from "../forms/client/ClientEmployerInformation";
import { ClientPersonalInformation } from "../forms/client/ClientPersonalInformation";
import { clientsSelectors } from "../redux/clients/selectors";

export const EditCase = () => {
  const { caseID } = useParams();
  const allClients = useSelector(clientsSelectors.selectAll);

  const user = allClients.find((client) => client.id === caseID);

  return (
    <SectionWrapper>
      <Flex alignItems="start" flexDirection="column" flex="1">
        <SpecialHeading title="Edit case" fontSize="xl" />
        <Tabs isLazy variant="underline">
          <TabList gap={4}>
            <div className="flex gap-4 relative">
              <Tab>Personal Information</Tab>
              <Tab>Contact Information</Tab>
              <Tab>Employer Information</Tab>
            </div>
          </TabList>
          <Badge className=" right-0 left-0 bottom-0 mx-auto  !bg-transparent  my-4  font-bold  !rounded-none">
            <Heading size="xl">
              {`${user?.firstName} ${user?.lastName} / ${user?.visa}`}
            </Heading>
          </Badge>
          <TabPanels>
            <TabPanel>
              <ClientPersonalInformation />
            </TabPanel>
            <TabPanel>
              <ContactInfo />
            </TabPanel>
            <TabPanel>
              <ClientEmployerInformation />
            </TabPanel>
          </TabPanels>
        </Tabs>
        {/* <Button color="red">Delete Case</Button> */}
      </Flex>
    </SectionWrapper>
  );
};
