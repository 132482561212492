import { Configuration, OpenAIApi } from "openai";
import axiosInstance from "./axiosConfig";
import { DataDocs } from "../types/tables-data";
import {
  GenerateDraftParams,
  GeneratePacketParams
} from "../types/drafts.type";

const configuration = new Configuration({
  apiKey: process.env.REACT_APP_OPENAI_API_KEY
});

export const openai = new OpenAIApi(configuration);

export const getMLVersionString = async () => {
  try {
    const baseURL = process.env.REACT_APP_API_BASE_URL?.replace("/api", "");
    if (baseURL) {
      const response = await axiosInstance.get(baseURL);
      console.log(response);
      return response.data;
    }
    return null;
  } catch (error) {
    console.error("Error getting ML version", error);
    throw error;
  }
};

export const getLoginAsToken = async (targetUserId: string) => {
  try {
    const response = await axiosInstance.post("/admin/generate_token", {
      userId: targetUserId
    });
    return response.data;
  } catch (error) {
    console.error("Error extracting information from the text:", error);
    throw error;
  }
};

export const extractFromText = async (ocrData: string, docType: string) => {
  try {
    const response = await axiosInstance.post("/extract_from_text", {
      ocrData,
      docType
    });
    return response.data;
  } catch (error) {
    console.error("Error extracting information from the text:", error);
    throw error;
  }
};

export const extractInfoFromNIWEvidence = async (
  individualId: string,
  exhibits: string[],
  ids: string[]
) => {
  try {
    const response = await axiosInstance.post(
      "/extract_evidence_for_individual",
      {
        individualId,
        exhibits,
        ids,
        useAsync: true
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error extracting information from the text:", error);
    throw error;
  }
};

export const summarizeResume = async (visaType: string, resumeData: string) => {
  try {
    const response = await axiosInstance.post("/summarize_resume_for_visa", {
      visaType,
      resumeData
    });
    return response.data;
  } catch (error) {
    console.error("Error extracting information from the text:", error);
    return null;
  }
};
export const summarizeResumeWithUpdate = async (
  individualId: string,
  firebasePath: string
) => {
  try {
    const response = await axiosInstance.post(
      "/summarize_candidate_resume_by_individual",
      {
        firebasePath,
        individualId
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error Summarizing CV:", error);
    return null;
  }
};

export const summarizeExpertResume = async (firebase_path: string) => {
  try {
    const response = await axiosInstance.post("/summarize_expert_resume", {
      firebase_path
    });
    return response.data;
  } catch (error) {
    console.error("Error extracting information from the text:", error);
    return null;
  }
};

export const performOCR = async (uid: string, docs: DataDocs[]) => {
  try {
    if (docs.length === 0) {
      // Handle the case when the 'docs' array is empty
      console.warn("No documents to process.");
      return;
    }

    // Create an array of promises
    const ocrPromises = docs
      .filter(
        (doc) =>
          doc.sub_documents &&
          doc.sub_documents.some((subDoc) => subDoc.isMain === true)
      )
      .map(async (doc) => {
        const mainDoc = doc.sub_documents?.find(
          (subDoc) => subDoc.isMain === true
        );
        if (mainDoc) {
          const firebasePath = `documents/${uid}/evidence_docs/${doc.uid}/${mainDoc.uid}`;
          return axiosInstance.post("/api/ocr_file", {
            firebase_path: firebasePath
          });
        }

        // Add a default return value when mainDoc is not found
        return null;
      });

    // Wait for all OCR requests to complete
    const ocrResults = await Promise.all(ocrPromises);

    // Handle individual success cases here if needed
    ocrResults.forEach((result) => {
      if (result !== null) {
        // Handle each result
      }
    });
  } catch (error) {
    console.error("Error performing OCR:", error);
    // Handle errors here if necessary
  }
};

export const generateDraft = async ({
  individualId,
  draftType,
  expertId = null,
  tone = null,
  length = null,
  creativity = null,
  greeting = null,
  useAsync = false,
  referenceResume = false,
  isSupportLetter = false
}: GenerateDraftParams): Promise<any> => {
  try {
    const requestBody = {
      individualId,
      draftType,
      expertId,
      ...(tone && { tone }),
      ...(length && { length }),
      ...(creativity && { creativity }),
      ...(greeting && { greeting }),
      ...(useAsync && { useAsync }),
      ...(referenceResume && { referenceResume }),
      ...(isSupportLetter && { isSupportLetter })
    };

    const response = await axiosInstance.post("/create_draft", requestBody);
    return response.data;
  } catch (error) {
    console.error("Error extracting information from the text:", error);
    return null;
  }
};

export const generatePacket = async ({
  individualId,
  useAsync = false
}: GeneratePacketParams): Promise<any> => {
  try {
    const requestBody = {
      individualId,
      ...(useAsync && { useAsync })
    };

    const response = await axiosInstance.post(
      "/generate_case_packet",
      requestBody
    );
    return response.data;
  } catch (error) {
    console.error("Error extracting information from the text:", error);
    return null;
  }
};

export const createNewFirebaseUser = async (
  email: string,
  password: string,
  randomizePassword: boolean,
  role = "",
  firstName = "",
  lastName = "",
  practiceName = "",
  visaType = "",
  subscriptionStatus = "",
  lawyerRole = ""
) => {
  try {
    // Define args with a flexible structure
    const args: {
      email: string;
      password: string;
      randomizePassword: boolean;
      role?: string;
      firstName?: string;
      lastName?: string;
      practiceName?: string;
      visaType?: string;
      subscriptionStatus?: any;
      lawyerRole?: any;
    } = { email, password, randomizePassword };

    // Conditionally add optional parameters if they are not set to their default values
    if (role !== "") args.role = role;
    if (firstName !== "") args.firstName = firstName;
    if (lastName !== "") args.lastName = lastName;
    if (practiceName !== "") args.practiceName = practiceName;
    if (visaType !== "") args.visaType = visaType;
    if (subscriptionStatus !== "") args.subscriptionStatus = subscriptionStatus;
    if (lawyerRole !== "") args.lawyerRole = lawyerRole;

    const response = await axiosInstance.post("/create_new_user", args);
    return response.data;
  } catch (error) {
    console.error("Error adding new user:", error);
    throw error;
  }
};

export const getFirebaseUsers = async (
  orderBy = "",
  uid = "",
  email = "",
  maxResults = -1,
  pageToken = ""
) => {
  try {
    let paramString = "?";

    if (orderBy !== "") {
      paramString += `orderBy=${orderBy}`;
    }

    if (uid !== "") {
      paramString += `&uid=${uid}`;
    }

    if (email !== "") {
      paramString += `&email=${encodeURIComponent(email)}`;
    }

    if (maxResults !== -1) {
      paramString += `&maxResults=${maxResults}`;
    }

    if (pageToken !== "") {
      paramString += `&pageToken=${pageToken}`;
    }

    const response = await axiosInstance.get(`/admin/users${paramString}`);
    return response.data;
  } catch (error) {
    console.error("Error getting users:", error);
    throw error;
  }
};

export const updateFirebaseUser = async (uid: string, updates: object) => {
  try {
    // Send the request with only the provided parameters
    const response = await axiosInstance.post(`/admin/users/${uid}`, updates);
    return response.data;
  } catch (error) {
    console.error("Error updating user:", error);
    throw error;
  }
};

export const generateCaseResearchSummary = (uid: string) => {};

export const autoGenerateTopics = async (uid: string) => {
  try {
    const requestBody = {
      individualId: uid
    };

    const response = await axiosInstance.post(
      "/perform_case_research",
      requestBody
    );
    return response.data;
  } catch (error) {
    console.error("Error running case research:", error);
    return null;
  }
};

export const fetchDocumentCategoriesAndTypes = async () => {
  try {
    // Send the request with only the provided parameters
    const response = await axiosInstance.get(`/document_types`);
    return response.data;
  } catch (error) {
    console.error("Error updating user:", error);
    throw error;
  }
};

export const downloadFile = async (storagePath: string) => {
  try {
    const response = await axiosInstance.get("/download_file", {
      params: {
        storagePath // Include storagePath as a query parameter
      },
      responseType: "blob"
    });
    return response.data;
  } catch (error) {
    console.error("Error extracting information from the text:", error);
    throw error;
  }
};

export const addCustomPrompt = async (
  individualId: string,
  highlightedText: string,
  totalContext: string,
  rewriteOption?: string,
  toneOption?: string,
  customPrompt?: string
) => {
  try {
    const response = await axiosInstance.post("/add_prompts", {
      individualId,
      highlightedText,
      totalContext,
      rewriteOption,
      toneOption,
      customPrompt
    });

    return response.data;
  } catch (error) {
    console.error("Error adding custom prompt:", error);
    throw error;
  }
};

export const getDocumentType = async (file: File) => {
  const formData = new FormData();

  formData.append("file", file);

  const response = await axiosInstance.post("/check_resume", formData, {
    headers: { "Content-Type": "multipart/form-data" }
  });
  return response.data;
};
