import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Divider,
  Heading,
  Spacer,
  Text,
  useDisclosure
} from "@chakra-ui/react";
import { updateDoc } from "firebase/firestore";
import _ from "lodash";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { RichTextEditor } from "../components/common/RichTextEditor";
import DocumentInformation from "../components/individualTabs/individualDocuments/DocumentInformation";
import EditMainDocModal from "../components/individualTabs/individualDocuments/EditMainDocModal";
import { getEntityReference } from "../helpers/firebase_helpers";
import { useAddExtractInfo } from "../hooks/useAddExtractInfo";
import { updateDocument } from "../redux/documents/documentSlice";
import { VisaDocumentType } from "../redux/documents/types";
import { DataDocs } from "../types/tables-data";
import DocumentPreviewCard from "./DocumentPreviewCard";

type ExtractedEvidenceItemProps = {
  evidenceId: string;
  exhibit: string;
  text: { current: string; original: string };
  document?: DataDocs;
  documentType: VisaDocumentType;
};

const ExtractedEvidenceItem = ({
  evidenceId,
  exhibit,
  text,
  document,
  documentType
}: ExtractedEvidenceItemProps) => {
  const navigate = useNavigate();
  const { id, visaType } = useParams<{ id: string; visaType: string }>();

  const [summary, setSummary] = useState(text || { original: "", current: "" });
  const [isSaving, setIsSaving] = useState(false);
  const { onSaveEvidenceArguments } = useAddExtractInfo(id);
  const dispatch = useDispatch();

  const [documentToEdit, setDocumentToEdit] = useState<DataDocs | undefined>();
  const {
    isOpen: isEditOpen,
    onOpen: onEditOpen,
    onClose: onEditClose
  } = useDisclosure();

  const panelRefs = useRef<
    Record<string, HTMLButtonElement | HTMLDivElement | null>
  >({});

  useEffect(() => {
    if (documentToEdit) {
      onEditOpen();
    }
  }, [documentToEdit, onEditOpen]);

  const handleAccordionToggle = (panelKey: string, isOpen: boolean) => {
    if (!isOpen && panelRefs.current[panelKey]) {
      _.debounce(() => {
        panelRefs.current[panelKey]?.scrollIntoView({
          behavior: "smooth",
          block: "start"
        });
      }, 200)();
    }
  };

  const getDocumentSummary = () => {
    if (document?.type?.toLowerCase().includes("resume")) {
      return document?.summaryHtml;
    }
    return (
      document?.extracted_argument?.argument_html ||
      document?.extracted_argument?.argument_text
    );
  };
  const isEvidence = documentType === VisaDocumentType.Evidence;

  return (
    <Box className="h-full">
      <Heading size="md" mb={4}>
        Edit details
      </Heading>
      {document ? (
        <Box key={document.uid} className=" rounded-md my-2 h-full">
          <Text fontWeight="500" textAlign="left" fontSize="14" mb={2}>
            EXHIBIT {exhibit}
          </Text>
          <Accordion defaultIndex={[2]} allowMultiple gap={2}>
            {/* Document basic information */}

            <AccordionItem>
              {({ isExpanded }) => (
                <>
                  <AccordionButton
                    ref={(el) => {
                      panelRefs.current.documentInfo = el;
                    }}
                    onClick={() => {
                      handleAccordionToggle("documentInfo", isExpanded);
                    }}
                  >
                    Document information
                    <Spacer /> <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel>
                    <DocumentInformation
                      document={document}
                      visaDocumentType={documentType}
                    />
                  </AccordionPanel>
                </>
              )}
            </AccordionItem>

            <Divider my={2} visibility="hidden" />

            {/* Additional information */}
            <AccordionItem>
              {({ isExpanded }) => (
                <>
                  <AccordionButton
                    onClick={() => {
                      handleAccordionToggle("additionalInfo", isExpanded);
                    }}
                  >
                    Additional information
                    <Spacer /> <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel
                    ref={(el) => {
                      panelRefs.current.additionalInfo = el;
                    }}
                  >
                    <DocumentPreviewCard
                      document={document}
                      documentType={documentType}
                    />
                  </AccordionPanel>
                </>
              )}
            </AccordionItem>

            <Divider my={2} visibility="hidden" />

            {/* AI generated Summary */}
            <AccordionItem>
              {({ isExpanded }) => (
                <>
                  <AccordionButton
                    onClick={() => {
                      handleAccordionToggle("summary", isExpanded);
                    }}
                  >
                    AI-generated Summary
                    <Spacer /> <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel
                    ref={(el) => {
                      panelRefs.current.summary = el;
                    }}
                  >
                    <Box className="h-full">
                      <RichTextEditor
                        customHeight="auto"
                        saveText={async (html, text) => {
                          setIsSaving(true);

                          if (documentType === VisaDocumentType.Evidence) {
                            await onSaveEvidenceArguments(document, text, html);
                            dispatch(
                              updateDocument({
                                data: {
                                  ...document,
                                  extracted_argument: {
                                    ...document.extracted_argument,
                                    argument_text: text,
                                    argument_html: html
                                  }
                                },
                                type: documentType
                              })
                            );
                          }
                          if (documentType === VisaDocumentType.Standard) {
                            const docRef = getEntityReference(
                              "standardDocument",
                              {
                                documentId: document?.id ?? "",
                                individualId: id ?? ""
                              }
                            );
                            await updateDoc(docRef, {
                              summary: text,
                              summaryHtml: html
                            });
                            updateDocument({
                              data: {
                                ...document,
                                summary: text,
                                summaryHtml: html
                              },
                              type: documentType
                            });
                          }

                          setIsSaving(false);
                        }}
                        text={getDocumentSummary() ?? ""}
                      />
                    </Box>
                  </AccordionPanel>
                </>
              )}
            </AccordionItem>
          </Accordion>

          {documentToEdit !== undefined && (
            <EditMainDocModal
              isDeleting={false}
              document={documentToEdit}
              headerText="Edit Main Document"
              isOpen={isEditOpen}
              onClose={onEditClose}
              visaDocumentType={documentType}
            />
          )}
        </Box>
      ) : (
        ""
      )}
    </Box>
  );
};

export default ExtractedEvidenceItem;
