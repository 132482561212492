import { StorageReference, getBlob } from "firebase/storage";
import { convertFromHtml } from "../api/draftsApi";
import { supportedExtensionsString } from "../constans";

// Get filename from firebase url
export const getFileName = (url: string | null | undefined) => {
  if (url === null || url === undefined) return "";
  const decodedUrl = decodeURIComponent(url);
  const segments = decodedUrl.split("/");
  const lastSegment = segments.pop() || "";
  return lastSegment.split("?")[0] || "Unknown Document";
};

export const downloadFileAsBlob = async (
  fileRef: StorageReference
): Promise<string> => {
  try {
    // Get the blob from the storage reference
    const blob = await getBlob(fileRef);

    // Create an object URL from the blob
    const objectURL = URL.createObjectURL(blob);

    return objectURL;
  } catch (error) {
    // Handle any errors that occur during the process
    console.error("Error downloading file:", error);
    throw error;
  }
};

export const getFileFromUrl = async (url: string) => {
  try {
    const response = await fetch(url);

    // Check if the request was successful
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    // Get the file data
    const data = await response.blob();

    // Create a filename based on the URL or you could let the user specify one
    const filename = url.split("/").pop();

    // Create a new File object
    let file;
    if (filename) file = new File([data], filename, { type: data.type });

    return file;
  } catch (error) {
    console.error("Could not get file from URL:", error);
  }
  return null;
};

export const getMimeType = (str: string) => {
  const prepared = str.split(";");
  const type = prepared[0].split(":");
  return type[1];
};

export const reader = (file: File) =>
  new Promise((resolve, reject) => {
    const fr = new FileReader();
    fr.onload = () => resolve(fr);
    fr.onerror = (err) => reject(err);
    fr.readAsDataURL(file);
  });

// Extensions
export const handleUnsupportedExtension = (
  fileName: string,
  uploadInputRef: React.RefObject<HTMLInputElement>
) => {
  const getFileExtension = (fileName?: string): string | undefined => {
    if (!fileName) {
      return undefined;
    }
    return fileName.split(".").pop()?.toLowerCase();
  };

  const isUnsupportedExtension = (fileExtension: string) => {
    const unsupportedExtensions = ["doc"];
    return unsupportedExtensions.includes(fileExtension);
  };

  const fileExtension = getFileExtension(fileName);
  if (fileExtension && isUnsupportedExtension(fileExtension)) {
    alert(
      `File extension '.${fileExtension}' is not supported. Please try another file.`
    );
    if (uploadInputRef.current) {
      uploadInputRef.current.value = ""; // Clear the input
    }
    return true;
  }
  return false;
};

export const triggerDownload = (
  binaryData: ArrayBuffer,
  fileName: string,
  format: string
) => {
  const blob = new Blob([binaryData], { type: `application/${format}` });
  const url = URL.createObjectURL(blob);

  const a = document.createElement("a");
  a.href = url;
  a.download = fileName;
  document.body.appendChild(a);
  a.click();

  document.body.removeChild(a);
  URL.revokeObjectURL(url);
};

export const getFileExtension = (file: File) => {
  if (file && file.name) {
    return `.${file.name.split(".").pop()?.toLowerCase()}`;
  }
  return "";
};

export const filesHasUnsupportedExtension = (files: File[]) => {
  return files.some((file) => {
    const fileExtension = getFileExtension(file);
    return !supportedExtensionsString.includes(fileExtension ?? "");
  });
};

export const filterDocumentsBySupport = (files: File[], supported = true) => {
  let filteredFiles = [];

  filteredFiles = files.filter((file) => {
    if (file && file.name) {
      const fileExtension = getFileExtension(file);
      return supportedExtensionsString.includes(fileExtension) === supported;
    }
    return true;
  });

  return filteredFiles;
};
