import { Quill } from "react-quill";

const Inline = Quill.import("blots/inline");

export class ChangingText extends Inline {
  static create() {
    const node = super.create();
    node.setAttribute("class", "changing-text");
    return node;
  }
}

ChangingText.blotName = "changingText";
ChangingText.tagName = "mark";
ChangingText.className = "changing-text";
