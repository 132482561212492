import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  VStack
} from "@chakra-ui/react";
import { ChangeEvent, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import useFileActions from "../../../hooks/useFileActions";
import { documentSelectors } from "../../../redux/documents/selectors";
import { DATABASE, VISAVALUE } from "../../../types/tables-data";
import { Dropdown } from "../../common/Dropdown";
// eslint-disable-next-line import/no-cycle
import { UploadFilesPlaceholder } from "../../UploadFilesPlaceholder";
import UploadList from "./UploadList";
import { TableRef } from "../../../types/document";
import { VisaDocumentType } from "../../../redux/documents/types";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  tableRef: TableRef;
  storagePath: string;
  collectionPath: string;
  superClass: string;
  visaDocumentType: VisaDocumentType;
}

const UploadByCategoryModal: React.FC<Props> = ({
  isOpen,
  onClose,
  tableRef,
  storagePath,
  collectionPath,
  superClass,
  visaDocumentType
}) => {
  const { id, visaType } = useParams();
  const { handleFileActions } = useFileActions();
  const documentTypes = useSelector(documentSelectors.documentTypes);

  const storagePaths: Record<TableRef, string> = {
    evidence: `${DATABASE.INDIVIDUALS}/documents/${id}`,
    standard: `${DATABASE.INDIVIDUALS}/documents/${id}`,
    signed_expert_letters: `${DATABASE.INDIVIDUALS}/documents/${id}`
  };

  const [selectedCategory, setSelectedCategory] = useState<string | undefined>(
    undefined
  );
  const [selectedType, setSelectedType] = useState<string | undefined>(
    undefined
  );
  const [files, setFiles] = useState<File[]>([]);
  const { onOpen } = useDisclosure();

  // TODO : refactor with original map and handle migration where necessary
  const visaFromPath: { [key: string]: string } = {
    "EB-1A": VISAVALUE.EB1A,
    "EB-1B": VISAVALUE.EB1B,
    "EB-2-NIW": VISAVALUE.EB2NIW,
    "O-1-A": "O-1A",
    "O-1-B": "O-1B",
    "EB-1C": VISAVALUE.EB1C
  };

  useEffect(() => {
    if (!isOpen) {
      setSelectedCategory(undefined);
      setSelectedType(undefined);
    }
  }, [isOpen]);

  const categories = useMemo(() => {
    console.log(`[categories_testing] Memo hook triggered`);

    if (!documentTypes) {
      console.log(
        `[categories_testing] documentTypes or type is missing.`,
        documentTypes
      );
      return [];
    }

    if (tableRef === "signed_expert_letters") {
      return documentTypes[tableRef];
    }

    const visaDocument =
      tableRef === "evidence" && visaType
        ? documentTypes[tableRef][visaFromPath[visaType]]
        : documentTypes[tableRef];

    return visaDocument ? Object.keys(visaDocument) : [];
  }, [id]);

  const types = useMemo(() => {
    if (!documentTypes || !documentTypes[tableRef]) return [];

    const document =
      tableRef === "evidence" && visaType
        ? documentTypes[tableRef][visaFromPath[visaType]]
        : documentTypes[tableRef];

    return selectedCategory && document && document[selectedCategory]
      ? document[selectedCategory]
      : [];
  }, [id, selectedCategory]);

  const handleCategoryChange = (category: string) => {
    setSelectedCategory(category);
    setSelectedType("");
  };

  const handleTypeChange = (type: string) => {
    setSelectedType(type);
  };

  useEffect(() => {
    console.log("documentTypes from upload by cat:", documentTypes);
  }, [documentTypes]);

  const handleFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
    const newFiles = Array.from(e.target.files || []);
    setFiles((prev) => [...prev, ...newFiles]);
  };

  const handleRemoveFile = (index: number) => {
    setFiles((prev) => prev.filter((_, i) => i !== index));
  };

  const isFilesListEmpty = files.length === 0;
  const isCategoryEmpty = selectedCategory === undefined;

  const handleUploadFiles = async () => {
    console.log("storagePath: ", storagePath);
    console.log("collectionPath: ", collectionPath);

    if (!storagePath || !collectionPath) return;
    handleFileActions({
      files,
      path: storagePath,
      collectionPath,
      uid: id!,
      category: selectedCategory,
      type: selectedType,
      superClass: superClass!,
      visaDocumentType: visaDocumentType!
    });
    onClose();
  };

  const onCloseComplete = () => {
    console.log("Modal closed, resetting files.");
    setFiles([]); // Reset files only when the modal is fully closed
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} onCloseComplete={onCloseComplete}>
      <ModalOverlay />
      <ModalContent minWidth={files.length > 0 ? "66vw" : "46vw"}>
        <ModalHeader>Upload by category</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex columnGap={8} justifyContent="start" alignItems="start">
            <UploadList files={files} onRemove={handleRemoveFile} />
            <Box>
              <VStack spacing={4} align="stretch" mr={8}>
                <FormControl>
                  <FormLabel>Category</FormLabel>
                  <Dropdown
                    title="Select Category"
                    selectedValue={selectedCategory}
                    onItemSelect={handleCategoryChange}
                    list={categories}
                  />
                </FormControl>

                {selectedCategory && types.length > 0 && (
                  <FormControl>
                    <FormLabel>Document Type (Optional)</FormLabel>
                    <Dropdown
                      list={types}
                      title="Select Type"
                      selectedValue={selectedType}
                      onItemSelect={handleTypeChange}
                    />
                  </FormControl>
                )}

                <Box w="40vw">
                  <UploadFilesPlaceholder
                    styleIndex={1}
                    title=""
                    subTitle=""
                    onUpload={(fileList: FileList | null) => {
                      if (fileList) {
                        const newFiles = Array.from(fileList);
                        setFiles((prev) => [...prev, ...newFiles]);
                      }
                    }}
                  />
                </Box>
              </VStack>
            </Box>
          </Flex>
        </ModalBody>

        <ModalFooter>
          <Button variant="neutralStateButton" onClick={onClose}>
            Cancel
          </Button>
          <Button
            isDisabled={isCategoryEmpty || isFilesListEmpty}
            variant="primaryFilled"
            ml={3}
            onClick={handleUploadFiles}
          >
            Upload
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default UploadByCategoryModal;
