/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { doc, serverTimestamp, updateDoc } from "firebase/firestore";
import { Box, Button, Checkbox, Flex, Text } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { debounce } from "lodash";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import { Header } from "../components/Header";
import termsText from "../terms";
import { db } from "../api/firebaseApi";
import { lawyerSelectors } from "../redux/lawyer/selectors";

const HtmlRenderer = ({ htmlContent="" }) => {
  return (
    <div
      style={{ width: '100%', backgroundColor: '#ffffff'}}
      dangerouslySetInnerHTML={{ __html: htmlContent }}
    />
  );
};

function AcceptAgreement() {
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(false);
  const termsRef = useRef<HTMLDivElement>(null);
  const uid = useSelector(lawyerSelectors.selectUid);
  const navigate = useNavigate();
  const [isChecked, setIsChecked] = useState<boolean>(false);

  const handleScroll = useCallback(
    debounce(() => {
      if (termsRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = termsRef.current;
        const isBottom = scrollTop + clientHeight >= scrollHeight - 1;

        if (isBottom) {
          setIsScrolledToBottom(true);
        } else {
          setIsScrolledToBottom(false);
        }
      }
    }, 100),
    []
  );

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.target.checked);
  };

  useEffect(() => {
    if (termsRef.current) {
      termsRef.current.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (termsRef.current) {
        termsRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  const handleAccept = async () => {
    const docRef = doc(db, "lawyers", uid);
    await updateDoc(docRef, {
      hasAcceptedTerms: true,
      dateAcceptedTerms: serverTimestamp() 
    });
    navigate("/cases");
  };


  return (
    <div style={{ overflowY: 'scroll', height: '100vh'}}>
      <Header />
      <Text
        fontSize="16px"
        color="#000"
        lineHeight="normal"
        fontStyle="normal"
        fontWeight="700"
        marginTop="20px"
      >
        In order to continue using the platform, please read and accept the
        service agreement below.
      </Text>

      <Text
        fontSize="16px"
        color="#000"
        lineHeight="normal"
        fontStyle="normal"
        fontWeight="700"
        marginTop="20px"
      >
        Scroll to the bottom to accept.
      </Text>

      <Flex
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
        p={4}
      >
        <Box
          ref={termsRef}
          width="80%"
          height="70vh"
          overflowY="scroll"
          border="1px solid gray"
          borderRadius="md"
          p={4}
          mb={4}
          whiteSpace="pre-wrap"
          textAlign="left"
          lineHeight="1.5"
          backgroundColor="#ffffff"
        >
        <HtmlRenderer htmlContent={termsText} />
        </Box>

        <Box>
          <Checkbox
            size="lg"
            isChecked={isChecked}
            onChange={handleCheckboxChange}
            isDisabled={!isScrolledToBottom}
            sx={{
              "& .chakra-checkbox__control": {
                width: "28px", // Customize the width
                height: "28px", // Customize the height
                borderColor: isScrolledToBottom ? "gray.500" : "gray.400",
                backgroundColor: isScrolledToBottom ? "white" : "gray.200",
                opacity: isScrolledToBottom ? 1 : 0.8,
                _hover: {
                  borderColor: isScrolledToBottom ? "gray.600" : "gray.400",
                },
              },
              "& .chakra-checkbox__control[data-checked]": {
                backgroundColor: isScrolledToBottom ? "blue.500" : "gray.400",
                borderColor: isScrolledToBottom ? "blue.500" : "gray.400",
              },
              "& .chakra-checkbox__icon": {
                fontSize: "5rem", // Make the checkmark larger
              },
              "& .chakra-checkbox__label": {
                color: isScrolledToBottom ? "black" : "gray.500",
                fontSize: "lg", // Customize the font size
              },
            }}
          >
            <Text fontSize="20px">
            I agree to the terms and conditions as set out by the service agreement.
            </Text>
          </Checkbox>
        </Box>

        <Flex mt={4}>
          <Button
            mr={10}
            variant="primaryFilled"
            onClick={handleAccept}
            size="lg"
            isDisabled={!isChecked || !isScrolledToBottom}
          >
            I Accept
          </Button>
        </Flex>
      </Flex>
    </div>
  );
}

export default AcceptAgreement;
