import { arrayMove } from "@dnd-kit/sortable";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  DataDocs,
  SubdocumenstWithParentId,
  SubdocumentWithParentId
} from "../../types/tables-data";
import {
  generateDocumentUrls,
  generateSingleDocumentUrl
} from "./documentsThunk";
import { ExtractedData, VisaDocumentType } from "./types";

export interface DocumentState {
  evidenceDocs: DataDocs[];
  newEvidenceDocs: DataDocs[];
  standardDocs: DataDocs[];
  newStandardDocs: DataDocs[];
  signedExpertLetters: DataDocs[];
  extractedData: ExtractedData | null;
  selectedEvidenceDoc: DataDocs | null;
  evidenceExhibitMap: Record<string, string> | null;
  standardExhibitMap: Record<string, string> | null;
  isLoadingGetEvidence: boolean;
  documentTypes: any;
}

const initialState: DocumentState = {
  evidenceDocs: [],
  standardDocs: [],
  newStandardDocs: [],
  newEvidenceDocs: [],
  signedExpertLetters: [],
  selectedEvidenceDoc: null,
  extractedData: null,
  evidenceExhibitMap: null,
  standardExhibitMap: null,
  isLoadingGetEvidence: false,
  documentTypes: null
};

export interface DocumentReducer {
  document: DocumentState;
}

export interface RemoveDocPayload {
  evidenceDocId: string;
  docId: string;
}

export interface RemoveStandardDocument {
  filePath: string;
}

export interface DocumentsPayload {
  type: VisaDocumentType;
  data: DataDocs[] | DataDocs[];
}

export interface DocumentPayload {
  type: VisaDocumentType;
  data: DataDocs | DataDocs;
}

export interface SubDocumentPayload {
  type: VisaDocumentType;
  data: SubdocumentWithParentId;
}

export interface SubDocumentsPayload {
  type: VisaDocumentType;
  data: SubdocumenstWithParentId;
}

export const documentSlice = createSlice({
  name: "document",
  initialState,
  reducers: {
    setDocumentTypes: (state, action: PayloadAction<any>) => {
      state.documentTypes = action.payload;
    },
    setStandardDocs: (state, action: PayloadAction<DataDocs[]>) => {
      state.standardDocs = action.payload;
    },
    setDocuments: (state, action: PayloadAction<DocumentsPayload>) => {
      const { data, type } = action.payload;
      switch (type) {
        case VisaDocumentType.Standard:
          state.newStandardDocs = data as DataDocs[];
          break;
        case VisaDocumentType.Evidence:
          state.evidenceDocs = data as DataDocs[];
          break;
        case VisaDocumentType.SignedExpertLetters:
          state.signedExpertLetters = data as DataDocs[];
          break;
        default:
          break;
      }
    },
    addNewDocument: (state, action: PayloadAction<DocumentPayload>) => {
      const { data, type } = action.payload;
      switch (type) {
        case VisaDocumentType.Standard: {
          const documentExist = state.newStandardDocs.find(
            (el) => el.id === data.id
          );
          if (!documentExist) state.newStandardDocs.push(data as DataDocs);
          break;
        }
        case VisaDocumentType.Evidence:
          state.evidenceDocs.push(data as DataDocs);
          break;
        case VisaDocumentType.SignedExpertLetters:
          state.signedExpertLetters.push(data as DataDocs);
          break;
        default:
          break;
      }
    },
    setSubDocuments: (state, action: PayloadAction<SubDocumentsPayload>) => {
      const {
        data: { documents, id },
        type
      } = action.payload;
      switch (type) {
        case VisaDocumentType.Standard: {
          const index = state.newStandardDocs.findIndex((doc) => doc.id === id);
          if (index !== -1) {
            state.newStandardDocs[index].sub_documents = documents;
          }
          break;
        }
        case VisaDocumentType.Evidence: {
          const index = state.evidenceDocs.findIndex((doc) => doc.id === id);
          if (index !== -1) {
            state.evidenceDocs[index].sub_documents = documents;
          }
          break;
        }
        case VisaDocumentType.SignedExpertLetters: {
          const index = state.signedExpertLetters.findIndex(
            (doc) => doc.id === id
          );
          if (index !== -1) {
            state.signedExpertLetters[index].sub_documents = documents;
          }
          break;
        }
        default:
          break;
      }
    },
    addSubDocument: (state, action: PayloadAction<SubDocumentPayload>) => {
      const { data, type } = action.payload;
      switch (type) {
        case VisaDocumentType.Standard: {
          const index = state.newStandardDocs.findIndex(
            (doc) => doc.id === data.id
          );
          if (index !== -1) {
            state.newStandardDocs[index].sub_documents?.push(
              data.document as DataDocs
            );
          }
          break;
        }
        case VisaDocumentType.Evidence: {
          const index = state.evidenceDocs.findIndex(
            (doc) => doc.id === data.id
          );
          if (index !== -1) {
            state.evidenceDocs[index].sub_documents?.push(
              data.document as DataDocs
            );
          }
          break;
        }
        case VisaDocumentType.SignedExpertLetters: {
          const index = state.signedExpertLetters.findIndex(
            (doc) => doc.id === data.id
          );
          if (index !== -1) {
            state.signedExpertLetters[index].sub_documents?.push(
              data.document as DataDocs
            );
          }
          break;
        }
        default:
          break;
      }
    },
    deleteSubDocument: (state, action: PayloadAction<SubDocumentPayload>) => {
      const {
        data: { document: subDocument, id },
        type
      } = action.payload;
      switch (type) {
        case VisaDocumentType.Standard: {
          const documentIndex = state.newStandardDocs.findIndex(
            (doc) => doc.id === id
          );
          if (documentIndex !== -1) {
            state.newStandardDocs[documentIndex].sub_documents =
              state.newStandardDocs[documentIndex].sub_documents?.filter(
                (subDoc) => subDocument.id !== subDoc.id
              );
          }
          break;
        }
        case VisaDocumentType.Evidence: {
          const documentIndex = state.evidenceDocs.findIndex(
            (doc) => doc.id === id
          );
          if (documentIndex !== -1) {
            state.evidenceDocs[documentIndex].sub_documents =
              state.evidenceDocs[documentIndex].sub_documents?.filter(
                (subDoc) => subDocument.id !== subDoc.id
              );
          }
          break;
        }
        case VisaDocumentType.SignedExpertLetters: {
          const documentIndex = state.signedExpertLetters.findIndex(
            (doc) => doc.id === id
          );
          if (documentIndex !== -1) {
            state.signedExpertLetters[documentIndex].sub_documents =
              state.signedExpertLetters[documentIndex].sub_documents?.filter(
                (subDoc) => subDocument.id !== subDoc.id
              );
          }
          break;
        }
        default:
          break;
      }
    },
    updateSubDocument: (state, action: PayloadAction<SubDocumentPayload>) => {
      const {
        data: { document: subDocument, id },
        type
      } = action.payload;

      switch (type) {
        case VisaDocumentType.Standard: {
          const documentIndex = state.newStandardDocs.findIndex(
            (doc) => doc.id === id
          );
          if (documentIndex !== -1) {
            const subDocumentList =
              state.newStandardDocs[documentIndex].sub_documents;
            if (subDocumentList) {
              const subDocumentIndex = subDocumentList.findIndex(
                (doc) => doc.id === subDocument.id
              );
              if (subDocumentIndex !== -1) {
                subDocumentList[subDocumentIndex] = { ...subDocument };
              }
            }
          }
          break;
        }
        case VisaDocumentType.Evidence: {
          const documentIndex = state.evidenceDocs.findIndex(
            (doc) => doc.id === id
          );
          if (documentIndex !== -1) {
            const subDocumentList =
              state.evidenceDocs[documentIndex].sub_documents;
            if (subDocumentList) {
              const subDocumentIndex = subDocumentList.findIndex(
                (doc) => doc.id === subDocument.id
              );
              if (subDocumentIndex !== -1) {
                subDocumentList[subDocumentIndex] = { ...subDocument };
              }
            }
          }
          break;
        }
        case VisaDocumentType.SignedExpertLetters: {
          const documentIndex = state.signedExpertLetters.findIndex(
            (doc) => doc.id === id
          );
          if (documentIndex !== -1) {
            const subDocumentList =
              state.signedExpertLetters[documentIndex].sub_documents;
            if (subDocumentList) {
              const subDocumentIndex = subDocumentList.findIndex(
                (doc) => doc.id === subDocument.id
              );
              if (subDocumentIndex !== -1) {
                subDocumentList[subDocumentIndex] = { ...subDocument };
              }
            }
          }
          break;
        }
        default:
          break;
      }
    },
    deleteDocument: (state, action: PayloadAction<DocumentPayload>) => {
      const { data, type } = action.payload;
      switch (type) {
        case VisaDocumentType.Standard:
          state.newStandardDocs = state.newStandardDocs.filter(
            (doc) => doc.id !== data.id
          );
          break;
        case VisaDocumentType.Evidence:
          state.evidenceDocs = state.evidenceDocs.filter(
            (doc) => doc.id !== data.id
          );
          break;
        case VisaDocumentType.SignedExpertLetters:
          state.signedExpertLetters = state.signedExpertLetters.filter(
            (doc) => doc.id !== data.id
          );
          break;
        default:
          break;
      }
    },
    updateDocument: (state, action: PayloadAction<DocumentPayload>) => {
      const { data, type } = action.payload;
      switch (type) {
        case VisaDocumentType.Standard: {
          const index = state.newStandardDocs.findIndex(
            (doc) => doc.id === data.id
          );
          if (index !== -1) {
            const oldDocument = state.newStandardDocs[index];
            state.newStandardDocs[index] = {
              ...oldDocument,
              ...data,
              sub_documents: oldDocument.sub_documents
            };
          }
          break;
        }
        case VisaDocumentType.Evidence: {
          const index = state.evidenceDocs.findIndex(
            (doc) => doc.id === data.id
          );
          if (index !== -1) {
            const oldDocument = state.evidenceDocs[index];
            state.evidenceDocs[index] = {
              ...oldDocument,
              ...data,
              sub_documents: oldDocument.sub_documents
            };
          }
          break;
        }
        case VisaDocumentType.SignedExpertLetters: {
          const index = state.signedExpertLetters.findIndex(
            (doc) => doc.id === data.id
          );
          if (index !== -1) {
            const oldDocument = state.signedExpertLetters[index];
            state.signedExpertLetters[index] = {
              ...oldDocument,
              ...(data as DataDocs)
            };
          }
          break;
        }
        default:
          break;
      }
    },
    setEvidenceDocs: (state, action: PayloadAction<DataDocs[]>) => {
      state.evidenceDocs = action.payload;
    },
    moveDocument: (
      state,
      action: PayloadAction<{ oldIndex: number; newIndex: number }>
    ) => {
      const { newIndex, oldIndex } = action.payload;
      state.evidenceDocs = arrayMove(state.evidenceDocs, oldIndex, newIndex);
    },
    setIsLoadingGetEvidence: (state, action: PayloadAction<boolean>) => {
      state.isLoadingGetEvidence = action.payload;
    },
    setEvidenceExhibitMap: (
      state,
      action: PayloadAction<Record<string, string>>
    ) => {
      const data = action.payload;
      state.evidenceExhibitMap = data;
    },
    setStandardExhibitMap: (
      state,
      action: PayloadAction<Record<string, string>>
    ) => {
      const data = action.payload;
      state.standardExhibitMap = data;
    },
    setSignedLetters: (state, action: PayloadAction<DataDocs[]>) => {
      state.signedExpertLetters = action.payload;
    },
    setSelectedEvidenceD: (state, action: PayloadAction<DataDocs | null>) => {
      state.selectedEvidenceDoc = action.payload;
    },
    removeEvidenceDoc: (state, action: PayloadAction<RemoveDocPayload>) => {
      const { evidenceDocId, docId } = action.payload;
      const evidenceDocIndex = state.evidenceDocs.findIndex(
        (doc) => doc.uid === evidenceDocId
      );
      if (evidenceDocIndex !== -1) {
        state.evidenceDocs[evidenceDocIndex].sub_documents = state.evidenceDocs[
          evidenceDocIndex
        ].sub_documents?.filter((doc) => doc.uid !== docId);
      }
    },
    removeAllEvidenceExtractions: (state) => {
      state.evidenceDocs.forEach((evidence) => {
        evidence.extracted_argument = null;
      });
    },
    removeStandardDoc: (
      state,
      action: PayloadAction<RemoveStandardDocument>
    ) => {
      state.standardDocs = state.standardDocs.filter(
        (document) => document.filePath !== action.payload.filePath
      );
    },
    setExtractedDataD: (state, action: PayloadAction<ExtractedData | null>) => {
      state.extractedData = action.payload;
    },
    clearAllDocuments: () => initialState
  },
  extraReducers: (builder) => {
    builder.addCase(generateDocumentUrls.fulfilled, (state, action) => {
      action.payload.forEach(({ id, fileUrl, type, mainDocId }) => {
        switch (type) {
          case VisaDocumentType.Standard: {
            if (mainDocId) {
              const mainDocIndex = state.newStandardDocs.findIndex(
                (doc) => doc.id === mainDocId
              );

              if (mainDocIndex !== -1) {
                const subDocIndex = state.newStandardDocs[
                  mainDocIndex
                ].sub_documents?.findIndex((doc) => doc.id === id);

                if (subDocIndex !== -1) {
                  const subDocs =
                    state.newStandardDocs[mainDocIndex].sub_documents;
                  if (subDocs && subDocIndex) {
                    subDocs[subDocIndex].docUrl = fileUrl;
                  }
                }
              }
            } else {
              const docIndex = state.newStandardDocs.findIndex(
                (doc) => doc.id === id
              );
              if (docIndex !== -1) {
                state.newStandardDocs[docIndex].docUrl = fileUrl;
              }
            }
            break;
          }
          case VisaDocumentType.Evidence: {
            if (mainDocId) {
              const mainDocIndex = state.evidenceDocs.findIndex(
                (doc) => doc.id === mainDocId
              );

              if (mainDocIndex !== -1) {
                const subDocIndex = state.evidenceDocs[
                  mainDocIndex
                ].sub_documents?.findIndex((doc) => doc.id === id);

                if (subDocIndex !== -1) {
                  const subDocs =
                    state.evidenceDocs[mainDocIndex].sub_documents;

                  if (subDocs !== undefined && subDocIndex !== undefined) {
                    subDocs[subDocIndex].docUrl = fileUrl;
                  }
                }
              }
            } else {
              const docIndex = state.evidenceDocs.findIndex(
                (doc) => doc.id === id
              );
              if (docIndex !== -1) {
                state.evidenceDocs[docIndex].docUrl = fileUrl;
              }
            }
            break;
          }
          case VisaDocumentType.SignedExpertLetters: {
            const docIndex = state.newStandardDocs.findIndex(
              (doc) => doc.id === id
            );
            if (docIndex !== -1) {
              state.newStandardDocs[docIndex].docUrl = fileUrl;
            }
            break;
          }
          default:
            break;
        }
      });
    });
    // generate docUrl for every new added document
    builder.addCase(generateSingleDocumentUrl.fulfilled, (state, action) => {
      const { id, fileUrl, type } = action.payload;
      switch (type) {
        case VisaDocumentType.Standard: {
          const docIndex = state.newStandardDocs.findIndex(
            (doc) => doc.id === id
          );
          if (docIndex !== -1) {
            state.newStandardDocs[docIndex].docUrl = fileUrl;
          }
          break;
        }
        case VisaDocumentType.Evidence: {
          const docIndex = state.evidenceDocs.findIndex((doc) => doc.id === id);
          if (docIndex !== -1) {
            state.evidenceDocs[docIndex].docUrl = fileUrl;
          }
          break;
        }
        case VisaDocumentType.SignedExpertLetters: {
          const docIndex = state.signedExpertLetters.findIndex(
            (doc) => doc.id === id
          );
          if (docIndex !== -1) {
            state.signedExpertLetters[docIndex].docUrl = fileUrl;
          }
          break;
        }
        default:
          break;
      }
    });
  }
});

export const {
  setDocumentTypes,
  setStandardDocs,
  setDocuments,
  updateDocument,
  addNewDocument,
  deleteDocument,
  setEvidenceDocs,
  setSignedLetters,
  setSelectedEvidenceD,
  removeEvidenceDoc,
  setExtractedDataD,
  clearAllDocuments,
  removeStandardDoc,
  removeAllEvidenceExtractions,
  addSubDocument,
  setSubDocuments,
  deleteSubDocument,
  setEvidenceExhibitMap,
  setStandardExhibitMap,
  setIsLoadingGetEvidence,
  moveDocument,
  updateSubDocument
} = documentSlice.actions;

export default documentSlice.reducer;
