import dayjs from "dayjs";
import * as yup from "yup";

// personalInfo tab
export const clientPersonalSchema = yup.object().shape({
  firstName: yup.string().required("First Name is Required"),
  middleName: yup.string(),
  lastName: yup.string().required("Last Name is Required"),
  pronouns: yup
    .string()
    .notOneOf(["default"], "you must select an option")
    .required("You must select an option"),
  otherPronouns: yup.string().optional(),
  title: yup
    .string()
    .notOneOf(["default"], "you must select an option")
    .required("You must select an option")
  // birthDay: yup.date().max(new Date()),
  // countryob: yup.string(),
  // citizenship: yup.string()
});
export type ClientPersonalSchema = yup.InferType<typeof clientPersonalSchema>;

export const addClientPersonalSchema = clientPersonalSchema.shape({
  visa: yup.string().required("Visa Type is Required"),
  email: yup
    .string()
    .email("Please enter a valid email")
    .required("Email is Required")
});
// ContactInfo tab
export const clientContactSchema = yup.object().shape({
  mailingAddress: yup.object({
    street: yup.string(),
    city: yup.string(),
    state: yup.string(),
    zipCode: yup.string()
  }),
  physicalAddress: yup.object({
    street: yup.string(),
    city: yup.string(),
    state: yup.string(),
    zipCode: yup.string()
  }),
  phone: yup.string(),
  email: yup.string().required("Email is required").email("Invalid email")
});

export const employerInfoSchema = yup.object({
  legalBusinessName: yup.string(),
  dba: yup.string(),
  businessAddress: yup.object({
    street: yup.string(),
    city: yup.string(),
    state: yup.string(),
    zipCode: yup.string()
  }),
  contactName: yup.string(),
  contactTitle: yup.string()
});
