import { useMemo } from "react";
import { useSelector } from "react-redux";
import { individualSelectors } from "../redux/individual/selectors";
import { lawyerSelectors } from "../redux/lawyer/selectors";
import { VISAVALUE } from "../types/tables-data";

type UserDetails = {
  userFullName: string;
  userType: string;
  avatarSrc: string;
  id: string;
  visaType?: VISAVALUE;
  role?: string;
  email: string;
};

export const useGetUserDetails = () => {
  const individual = useSelector(individualSelectors.selectAll);
  const lawyer = useSelector(lawyerSelectors.selectAll);

  const userDetails: UserDetails = useMemo(() => {
    if (lawyer.uid)
      return {
        userFullName: `${lawyer.firstName} ${lawyer.lastName}`,
        avatarSrc: lawyer.avatarSrc,
        userType: "Lawyer",
        id: lawyer.uid,
        role: lawyer.role,
        email: lawyer.email
      };

    if (individual.uid)
      return {
        userFullName: `${individual.firstName} ${individual.lastName}`,
        avatarSrc: individual.avatarSrc,
        userType: "Individual",
        id: individual.uid,
        visaType: individual.visaType,
        email: individual.email
      };

    return { userFullName: "", userType: "", avatarSrc: "", id: "", email: "" };
  }, [lawyer, individual]);

  return {
    userDetails
  };
};
