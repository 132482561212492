import { useToast } from "@chakra-ui/react";
import { addDoc, collection, doc, setDoc } from "firebase/firestore";
import { ref, uploadBytes } from "firebase/storage";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { db, storage } from "../api/firebaseApi";
import { createNewFirebaseUser } from "../api/OpenAIApi";
import { getStoragePathByVisaType } from "../helpers/helpers";
import {
  addNewActiveClient,
  ClientStateWithNoQuestionnaire,
  initialClientState
} from "../redux/clients/clientsSlice";
import { VisaDocumentType } from "../redux/documents/types";
import { DATABASE } from "../types/tables-data";

export const useAddClientData = (uid: string) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const toast = useToast();
  const dispatch = useDispatch();

  const addClientData = async (
    values: Partial<ClientStateWithNoQuestionnaire>,
    file: File | null
  ) => {
    setIsSubmitting(true);
    try {
      const userRef = await createNewFirebaseUser(
        values.email!,
        "default",
        true,
        "individual"
      );
      const clientDataRef = doc(db, DATABASE.INDIVIDUALS, userRef.uid);
      const createdDate = new Date().getTime();

      const clientData = {
        ...values,
        lawyerId: uid,
        isActive: true,
        createdDate,
        isDeleted: false
      } as ClientStateWithNoQuestionnaire;
      await setDoc(clientDataRef, clientData, { merge: true });

      dispatch(
        addNewActiveClient({
          ...initialClientState,
          ...clientData,
          id: clientDataRef.id
        })
      );
      if (file) {
        const filePath = getStoragePathByVisaType(
          VisaDocumentType.Standard,
          userRef.uid
        );
        const fileRef = ref(storage, `${filePath}/${file.name}`);
        await uploadBytes(fileRef, file);
        const documentData = {
          filePath: fileRef.fullPath,
          docNames: file.name,
          uploadDate: Date.now(),
          isDeleted: false
        };
        await addDoc(
          collection(db, `/documents/${userRef.uid}/docs`),
          documentData
        );
      }
      return { success: true, uid: userRef.uid };
    } catch (error) {
      toast({
        title: "Error",
        description: "Individual with the same email already exists!",
        status: "error"
      });
      return { success: false };
    } finally {
      setIsSubmitting(false);
    }
  };

  return { addClientData, isSubmitting };
};
