import {
  Box,
  Button,
  Flex,
  Icon,
  Stack,
  Text,
  useToast
} from "@chakra-ui/react";
import { Formik, FormikHelpers } from "formik";
import { useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getDocumentType } from "../api/OpenAIApi";
import { DomainFilter } from "../components/DomainFilter";
import { UploadFilesPlaceholder } from "../components/UploadFilesPlaceholder";
import { InputFieldInfoModal } from "../components/common/InputFieldInfoModal";
import { SectionWrapper } from "../components/common/SectionWrapper";
import { TargetBox } from "../components/inputDnD/TargetBox";
import { InputField } from "../forms/InputField";
import { SelectField } from "../forms/SelectField";
import { addClientPersonalSchema } from "../forms/client/Client.schema";
import { isValidVisaKey } from "../helpers/string_helpers";
import { useAddClientData } from "../hooks/useAddClientData";
import {
  initialNewCaseFormState,
  NewCaseFormState
} from "../redux/clients/clientsSlice";
import {
  MapVisaToFeatureType,
  visaTypeToPath,
  VISAVALUE
} from "../types/tables-data";

export const NewCase = () => {
  const uid = useSelector((state: any) => state.lawyer.uid);
  const [file, setFile] = useState<File | null>(null);
  const [isClassifyingResume, setIsClassifyingResume] = useState(false);
  const toast = useToast();
  const navigate = useNavigate();
  const { addClientData, isSubmitting } = useAddClientData(uid);

  const transformExtractedFields = (data: any): Partial<NewCaseFormState> => {
    const { extracted_fields: extractedFields } = data;
    return {
      firstName: extractedFields.resume_first_name || "",
      lastName: extractedFields.resume_last_name || "",
      email: extractedFields.resume_email_address || ""
      // employerPetitioner: extractedFields.resume_last_employer || ""
    };
  };

  const handleResumeUpload = async (
    uploadedFile: File,
    currentValues: NewCaseFormState,
    setValues: (values: NewCaseFormState) => void
  ) => {
    setIsClassifyingResume(true);
    try {
      const response = await getDocumentType(uploadedFile);
      if (response.extracted_fields && response.is_resume) {
        const transformedFields = transformExtractedFields(response);
        setValues({ ...currentValues, ...transformedFields });
      } else {
        toast({
          title: "Unsupported Document Type",
          status: "error",
          description: "Please upload a valid resume file to proceed"
        });
      }
    } catch (error) {
      console.error("Error uploading resume:", error);
    } finally {
      setIsClassifyingResume(false);
    }
  };

  const handleFormSubmit = async (
    values: NewCaseFormState,
    { setSubmitting }: FormikHelpers<NewCaseFormState>
  ) => {
    try {
      const response = await addClientData(values, file);
      const { visa } = values;
      if (response.success) {
        toast({
          title: "Client added successfully",
          status: "success",
          duration: 3000
        });
        const visaPath =
          visa && isValidVisaKey(visa) ? visaTypeToPath[visa] : visa;
        const path = `/individual/${response.uid}/${visaPath}`;

        navigate(path);
      }
    } catch (error: any) {
      toast({
        title: "Error adding client",
        description: error.message || "Please try again",
        status: "error",
        duration: 3000
      });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <SectionWrapper>
      <Box>
        <Formik
          initialValues={initialNewCaseFormState}
          validationSchema={addClientPersonalSchema}
          onSubmit={handleFormSubmit}
        >
          {({ setValues, handleSubmit, values, dirty, isValid }) => (
            <Box p={4}>
              {/* Autofill Information Box */}
              <Box
                mb={6}
                p={4}
                borderWidth="1px"
                borderRadius="md"
                borderColor="#307F8D"
                boxShadow="0px 0px 8px #307F8D33"
              >
                <Flex alignItems="start" mb={4}>
                  <Icon viewBox="0 0 28 28" fill="#307F8D" w="32px" h="32px">
                    <path d="M17.0909 9.81818L18 7.81818L20 6.90909L18 6L17.0909 4L16.1818 6L14.1818 6.90909L16.1818 7.81818L17.0909 9.81818ZM17.0909 14.1818L16.1818 16.1818L14.1818 17.0909L16.1818 18L17.0909 20L18 18L20 17.0909L18 16.1818L17.0909 14.1818ZM11.6364 10.1818L9.81818 6.18182L8 10.1818L4 12L8 13.8182L9.81818 17.8182L11.6364 13.8182L15.6364 12L11.6364 10.1818ZM10.5382 12.72L9.81818 14.3055L9.09818 12.72L7.51273 12L9.09818 11.28L9.81818 9.69455L10.5382 11.28L12.1236 12L10.5382 12.72Z" />
                  </Icon>
                  <div>
                    <Text fontWeight="bold" color="text.display.light" mb={1}>
                      Autofill Case Information
                    </Text>
                    <Text color="text.display.light">
                      Save time by uploading the applicant's resume. The resume
                      will also be used to accurately classify documents later.
                    </Text>
                  </div>
                </Flex>
                {/* Upload Zone */}
                <Box
                  mb={6}
                  borderRadius="md"
                  borderStyle="dashed"
                  textAlign="center"
                  borderColor="gray.300"
                >
                  <DndProvider backend={HTML5Backend} key={4} context={window}>
                    <TargetBox
                      onDrop={(item) => {
                        const { files } = item;

                        if (files) {
                          const uploadedFile = files[0];
                          setFile(uploadedFile);
                          handleResumeUpload(uploadedFile, values, setValues);
                        }
                      }}
                    >
                      {!isClassifyingResume && (
                        <UploadFilesPlaceholder
                          styleIndex={2}
                          onUpload={(files) => {
                            if (files) {
                              const uploadedFile = files[0];
                              setFile(uploadedFile);
                              handleResumeUpload(
                                uploadedFile,
                                values,
                                setValues
                              );
                            }
                          }}
                          title="Upload resume"
                          subTitle=""
                        />
                      )}
                    </TargetBox>
                  </DndProvider>
                </Box>
              </Box>
              {/* Client Form */}
              <form onSubmit={handleSubmit}>
                <Stack spacing={4}>
                  <SelectField
                    name="visa"
                    label="Visa Type"
                    isRequired
                    placeHolder="Select"
                    options={MapVisaToFeatureType}
                  />
                  <Stack direction={{ base: "column", md: "row" }} spacing={4}>
                    <InputField
                      name="firstName"
                      label="First Name"
                      isRequired
                      isLoaded={isClassifyingResume}
                    />
                    <InputField
                      name="middleName"
                      label="Middle Name (Optional)"
                      isLoaded={isClassifyingResume}
                    />
                    <InputField
                      name="lastName"
                      label="Last Name"
                      isRequired
                      isLoaded={isClassifyingResume}
                    />
                  </Stack>
                  <Stack direction={{ base: "column", md: "row" }} spacing={4}>
                    <SelectField
                      name="title"
                      label="Title"
                      placeHolder="Select"
                      options={["Mr.", "Ms.", "Dr."]}
                      isRequired
                    />
                    <SelectField
                      name="pronouns"
                      label="Pronouns"
                      placeHolder="Select"
                      options={[
                        "He/Him/His",
                        "She/Her/Hers",
                        "They/Them/Theirs",
                        "Other"
                      ]}
                      isRequired
                    />
                  </Stack>
                  <Stack direction={{ base: "column", md: "row" }} spacing={4}>
                    <InputField
                      name="email"
                      label="Applicant's Email address"
                      isLoaded={isClassifyingResume}
                      withInfo={
                        <InputFieldInfoModal title="Applicant’s email address">
                          <Text>
                            Enter the foreign national's email address. The
                            foreign national will not be notified. This email
                            can later be used by the applicant to access
                            <DomainFilter> CaseBlink's</DomainFilter> individual
                            portal.
                          </Text>
                        </InputFieldInfoModal>
                      }
                      placeholder="email@email.com"
                      isRequired
                    />
                    <InputField
                      name="employerPetitioner"
                      label="Name of Employer Petitioner (Optional)"
                      withInfo={
                        <InputFieldInfoModal title="Employer Petitioner">
                          <Text>Leave blank if self-petitioner.</Text>
                        </InputFieldInfoModal>
                      }
                      placeholder="Optional"
                    />
                  </Stack>
                  <Flex justifyContent="end" gap={2}>
                    <Button
                      onClick={() => navigate("/cases")}
                      variant="secondaryOutline"
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      variant="primaryFilled"
                      isLoading={isSubmitting}
                      isDisabled={!isValid || !dirty}
                    >
                      Add Case
                    </Button>
                  </Flex>
                </Stack>
              </form>
            </Box>
          )}
        </Formik>
      </Box>
    </SectionWrapper>
  );
};
