import { Box, createIcon, Icon, IconProps } from "@chakra-ui/react";
import { FiUsers } from "react-icons/fi";
import { MdAnnouncement } from "react-icons/md";

export const ToolsIcon = createIcon({
  displayName: "ToolsIcon",
  path: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.97 7.26999C21.0627 7.17748 21.1362 7.06759 21.1864 6.94662C21.2366 6.82564 21.2624 6.69596 21.2624 6.56499C21.2624 6.43402 21.2366 6.30434 21.1864 6.18337C21.1362 6.0624 21.0627 5.95251 20.97 5.85999L18.14 3.02999C18.0475 2.93729 17.9376 2.86374 17.8166 2.81356C17.6957 2.76338 17.566 2.73755 17.435 2.73755C17.304 2.73755 17.1743 2.76338 17.0534 2.81356C16.9324 2.86374 16.8225 2.93729 16.73 3.02999L12.24 7.51999L8.35 3.62999C7.57 2.84999 6.3 2.84999 5.52 3.62999L3.62 5.52999C2.84 6.30999 2.84 7.57999 3.62 8.35999L7.51 12.25L3 16.76V21H7.24L11.76 16.48L15.65 20.37C16.6 21.32 17.88 20.97 18.48 20.37L20.38 18.47C21.16 17.69 21.16 16.42 20.38 15.64L16.49 11.75L20.97 7.26999ZM5.04 6.93999L6.93 5.03999L8.2 6.30999L7.02 7.49999L8.43 8.90999L9.62 7.71999L10.82 8.91999L8.92 10.82L5.04 6.93999ZM16.27 14.38L15.08 15.57L16.49 16.98L17.68 15.79L18.95 17.06L17.05 18.96L13.16 15.07L15.06 13.17L16.27 14.38ZM6.41 19H5V17.59L14.61 7.97999L15.91 9.27999L16.02 9.38999L6.41 19ZM16.02 6.55999L17.43 5.14999L18.84 6.55999L17.43 7.96999L16.02 6.55999Z"
        fill="#757575"
      />
    </svg>
  )
});

export const StudioIcon = createIcon({
  displayName: "StudioIcon",
  path: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.9377 6.89139L7.38182 11.4562C6.9213 11.9176 6.9213 12.6757 7.38182 13.1371C7.84237 13.5985 8.59891 13.5985 9.05945 13.1371L13.7469 8.44045C14.1581 8.02847 14.8325 8.02847 15.2518 8.44045C15.663 8.85242 15.663 9.52811 15.2518 9.9483L11.9377 13.2689L10.7042 14.5049C10.0709 15.1393 9.24858 15.4936 8.37688 15.5184C7.52161 15.5348 6.73216 15.2217 6.14828 14.6367C5.56441 14.0517 5.25191 13.2607 5.26835 12.4038C5.2848 11.5304 5.64664 10.7064 6.27986 10.0719L11.9788 4.3618V1L4.59402 8.39103C2.38186 10.6075 2.31607 14.167 4.46244 16.3176C5.48217 17.3394 6.83907 17.8914 8.29467 17.8914C8.33577 17.8914 8.37688 17.8914 8.41802 17.8914C9.89825 17.8584 11.3045 17.2569 12.3736 16.1858L12.4393 16.1199L13.6071 14.9498L16.9212 11.6292C17.3242 11.2255 18.0232 11.2255 18.4262 11.6292C18.6235 11.827 18.7387 12.0989 18.7387 12.379C18.7387 12.6592 18.6317 12.9311 18.4262 13.1288L12.0035 19.6382V23L20.1038 14.8097C20.7534 14.1588 21.1071 13.2937 21.1071 12.379C21.1071 11.4562 20.7534 10.5993 20.1038 9.9483C19.5117 9.35507 18.7387 9.00899 17.9163 8.95131C17.8587 8.16031 17.538 7.38578 16.9459 6.77603C15.6055 5.41648 13.2864 5.53184 11.9459 6.88317L11.9377 6.89139Z"
        fill="#757575"
      />
    </svg>
  )
});

export const CasesIcon = createIcon({
  displayName: "CasesIcon",
  path: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M14 6V4H10V6H14ZM4 8V19H20V8H4ZM20 6C21.11 6 22 6.89 22 8V19C22 20.11 21.11 21 20 21H4C2.89 21 2 20.11 2 19L2.01 8C2.01 6.89 2.89 6 4 6H8V4C8 2.89 8.89 2 10 2H14C15.11 2 16 2.89 16 4V6H20Z" />
    </svg>
  )
});
export const ResearchIcon = createIcon({
  displayName: "ResearchIcon",
  path: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 9H2V7H7V9ZM7 12H2V14H7V12ZM20.59 19L16.76 15.17C15.96 15.69 15.02 16 14 16C11.24 16 9 13.76 9 11C9 8.24 11.24 6 14 6C16.76 6 19 8.24 19 11C19 12.02 18.69 12.96 18.17 13.75L22 17.59L20.59 19ZM17 11C17 9.35 15.65 8 14 8C12.35 8 11 9.35 11 11C11 12.65 12.35 14 14 14C15.65 14 17 12.65 17 11ZM2 19H12V17H2V19Z"
        fill="#757575"
      />
    </svg>
  )
});
export const SummarizeIcon = createIcon({
  displayName: "SummarizeIcon",
  path: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 6H2V20C2 21.1 2.9 22 4 22H18V20H4V6ZM20 2H8C6.9 2 6 2.9 6 4V16C6 17.1 6.9 18 8 18H20C21.1 18 22 17.1 22 16V4C22 2.9 21.1 2 20 2ZM20 16H8V4H20V16ZM10 9H18V11H10V9ZM10 12H14V14H10V12ZM10 6H18V8H10V6Z"
        fill="#757575"
      />
    </svg>
  )
});
export const TranslateIcon = createIcon({
  displayName: "TranslateIcon",
  path: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.87 15.07L10.33 12.56L10.36 12.53C12.0541 10.6471 13.3199 8.41925 14.07 6H17V4H10V2H8V4H1V5.99H12.17C11.5 7.92 10.44 9.75 9 11.35C8.07 10.32 7.3 9.19 6.69 8H4.69C5.42 9.63 6.42 11.17 7.67 12.56L2.58 17.58L4 19L9 14L12.11 17.11L12.87 15.07ZM18.5 10H16.5L12 22H14L15.12 19H19.87L21 22H23L18.5 10ZM15.88 17L17.5 12.67L19.12 17H15.88Z"
        fill="#757575"
      />
    </svg>
  )
});
export const LeftIcon = createIcon({
  displayName: "LeftIcon",
  path: (
    <svg
      width="48"
      height="48"
      viewBox="48 48"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 6.00003H6V18H4V6.00003ZM14.293 5.29303L7.586 12L14.293 18.707L15.707 17.293L11.414 13H20V11H11.414L15.707 6.70703L14.293 5.29303Z"
        fill="#757575"
      />
    </svg>
  )
});
export const TemplatesIcon = createIcon({
  displayName: "TemplatesIcon",
  path: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M20 6V20H6V22H20C21.1 22 22 21.1 22 20V6H20Z" fill="#757575" />
      <path
        d="M16 2H4C2.9 2 2 2.9 2 4V16C2 17.1 2.9 18 4 18H16C17.1 18 18 17.1 18 16V4C18 2.9 17.1 2 16 2ZM9 16H4V11H9V16ZM16 16H11V11H16V16ZM16 9H4V4H16V9Z"
        fill="#757575"
      />
    </svg>
  )
});
export const PromptIcon = createIcon({
  displayName: "PromptIcon",
  path: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 7L20.94 4.94L23 4L20.94 3.06L20 1L19.06 3.06L17 4L19.06 4.94L20 7ZM8.5 7L9.44 4.94L11.5 4L9.44 3.06L8.5 1L7.56 3.06L5.5 4L7.56 4.94L8.5 7ZM20 12.5L19.06 14.56L17 15.5L19.06 16.44L20 18.5L20.94 16.44L23 15.5L20.94 14.56L20 12.5ZM17.71 9.12L14.88 6.29C14.68 6.1 14.43 6 14.17 6C13.91 6 13.66 6.1 13.46 6.29L2.29 17.46C2.1973 17.5525 2.12375 17.6624 2.07357 17.7834C2.02339 17.9043 1.99756 18.034 1.99756 18.165C1.99756 18.296 2.02339 18.4257 2.07357 18.5466C2.12375 18.6676 2.1973 18.7775 2.29 18.87L5.12 21.7C5.32 21.9 5.57 22 5.83 22C6.09 22 6.34 21.9 6.54 21.71L17.71 10.54C18.1 10.15 18.1 9.51 17.71 9.12ZM14.17 8.42L15.58 9.83L14.41 11L13 9.59L14.17 8.42ZM5.83 19.59L4.42 18.18L11.59 11L13 12.41L5.83 19.59Z"
        fill="#757575"
      />
    </svg>
  )
});

export const CustomIcon = ({
  label,
  ...rest
}: { label: string } & IconProps) => {
  if (label === "CasesIcon") return <CasesIcon {...rest} />;
  if (label === "StudioIcon") return <StudioIcon {...rest} />;
  if (label === "ToolsIcon") return <ToolsIcon {...rest} />;
  if (label === "TranslateIcon") return <TranslateIcon {...rest} />;
  if (label === "SummarizeIcon") return <SummarizeIcon {...rest} />;
  if (label === "ResearchIcon") return <ResearchIcon {...rest} />;
  if (label === "TemplatesIcon") return <TemplatesIcon {...rest} />;
  if (label === "PromptIcon") return <PromptIcon {...rest} />;
  if (label === "ToolsIcon") return <ToolsIcon {...rest} />;
  if (label === "UsersIcon")
    return (
      <Icon width={24} height={24} {...rest}>
        <FiUsers width={24} height={24} />
      </Icon>
    );
  if (label === "UpdatesIcon")
    return (
      <Icon width={24} height={24} {...rest}>
        <MdAnnouncement />
      </Icon>
    );
  return null;
};
