import { createSlice } from "@reduxjs/toolkit";

interface SiderbarState {
  opened: boolean | null;
}
const sidbarInitialState: SiderbarState = {
  opened: true
};

export interface SidebarReducer {
  sidebar: SiderbarState;
}

export const sidebarSlice = createSlice({
  name: "sidebar",
  initialState: sidbarInitialState,
  reducers: {
    toggleSidebar: (state) => {
      state.opened = !state.opened;
    }
  }
});

export const { toggleSidebar } = sidebarSlice.actions;

export default sidebarSlice.reducer;
