import React from "react";
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  Tag,
  Flex,
  Box,
  FormControl,
  FormLabel,
  FormErrorMessage,
  ChakraProps,
  TagLabel
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { FieldConfig, useField } from "formik";
import { VISAVALUE } from "../types/tables-data";

interface Props extends FieldConfig, ChakraProps {
  label: string;
  placeHolder: string;
  options: Record<Exclude<VISAVALUE, VISAVALUE.EMPTY>, string> | string[];
  isRequired?: boolean;
  formikHelpers?: any;
}

export const SelectField = ({
  label,
  options,
  placeHolder,
  isRequired = false,
  formikHelpers,
  ...props
}: Props) => {
  const [field, meta, helpers] = formikHelpers ?? useField(props.name);
  const { setValue } = helpers;

  // Type guard to check if options is a Record<VISAVALUE, string>
  const isRecord = (
    options: Props["options"]
  ): options is Record<Exclude<VISAVALUE, VISAVALUE.EMPTY>, string> =>
    !Array.isArray(options);

  const handleSelect = (value: string) => {
    setValue(value); // Update Formik field value
  };

  return (
    <FormControl
      {...(props as ChakraProps)}
      isRequired={isRequired}
      isInvalid={meta.touched && !!meta.error}
    >
      <FormLabel>{label}</FormLabel>
      <Menu>
      <MenuButton
  as={Button}
  rightIcon={<ChevronDownIcon />}
  variant="add"
  width="fit-content"
  borderRadius={8}
>
  <Flex alignItems="center" gap={2}>
    <Box>{field.value || placeHolder}</Box>
    {isRecord(options) && options[field.value as Exclude<VISAVALUE, VISAVALUE.EMPTY>]?.trim() && (
      <Tag
        size="sm"
        variant="outline"
        colorScheme="blue"
        borderRadius="md"
        px={3}
        py={1}
        fontWeight="bold"
        fontSize="xs"
      >
        <TagLabel>
          {options[field.value as Exclude<VISAVALUE, VISAVALUE.EMPTY>]}
        </TagLabel>
      </Tag>
    )}
  </Flex>
</MenuButton>


        <MenuList>
          {isRecord(options)
            ? Object.entries(options).map(([key, value]) => (
                <MenuItem
                  key={key}
                  onClick={() => handleSelect(key)}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box>{key}</Box>
                  {value && (
                    <Tag
                      size="sm"
                      variant="outline"
                      colorScheme="blue"
                      borderRadius="md"
                      px={3}
                      py={1}
                      fontWeight="bold"
                      fontSize="xs"
                    >
                      <TagLabel>{value}</TagLabel>
                    </Tag>
                  )}
                </MenuItem>
              ))
            : options.map((option, index) => (
                <MenuItem
                  key={index}
                  onClick={() => handleSelect(option)}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box>{option}</Box>
                </MenuItem>
              ))}
        </MenuList>
      </Menu>
      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  );
};
