import {
  Box,
  Button,
  Checkbox,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  Tooltip
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { MdAddCircle } from "react-icons/md";
import { Item } from "../../types/document";
import { getShortString } from "../../helpers/string_helpers";

interface Props {
  title: string;
  onItemSelect: (values: Record<string | string, Item | null>) => void;
  list: Record<string | string, Item | null>;
  selectedValues: Record<string | string, Item | null>;
  isDisabled?: boolean;
  selectAllInitially?: boolean;
  withToolTip?: boolean;
}

export const DropdownMulti = ({
  title,
  onItemSelect,
  list,
  selectedValues,
  isDisabled,
  selectAllInitially = false,
  withToolTip = false
}: Props) => {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false);
  const [initialLoad, setInitialLoad] = useState<boolean>(true);

  // Normalize list into an array of [key, Item | null] pairs
  const normalizedList: Array<[number | string, Item | null]> =
    Object.entries(list);

  // Categorize only if there's at least one non-null item
  const shouldCategorize = normalizedList.some(([_, value]) => value !== null);

  // Group items by category if categorization is enabled
  const groupByCategory = (
    items: Array<[number | string, Item | null]>
  ): Record<string, Array<[number | string, Item | null]>> => {
    if (!Array.isArray(items)) {
      throw new Error("Invalid input: items must be an array.");
    }

    return items.reduce((acc, entry) => {
      if (!Array.isArray(entry) || entry.length !== 2) {
        throw new Error(
          "Invalid item format: each entry must be a tuple [key, item]."
        );
      }

      const [key, item] = entry;
      const category = item?.category || "Uncategorized";
      if (acc[category]) {
        acc[category].push([key, item]);
      } else {
        acc[category] = [];
      }
      return acc;
    }, {} as Record<string, Array<[number | string, Item | null]>>);
  };

  // Filter list based on search term and handle categorization based on non-null values
  const filteredList = normalizedList.filter(
    ([key, item]) =>
      item === null ||
      item.type.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const groupedItems = shouldCategorize
    ? groupByCategory(filteredList)
    : { All: filteredList };

  // Automatically select all items on initial load if the prop is set to true
  useEffect(() => {
    if (initialLoad) {
      if (selectAllInitially) {
        const allSelected = normalizedList.reduce<
          Record<number | string, Item | null>
        >((acc, [key, item]) => {
          acc[key] = item;
          return acc;
        }, {});
        onItemSelect(allSelected);
      } else {
        onItemSelect(selectedValues);
      }
      setInitialLoad(false);
    }
  }, [
    initialLoad,
    normalizedList,
    onItemSelect,
    selectAllInitially,
    selectedValues
  ]);

  const handleItemClick = (key: string | string, event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();

    // // Convert the key to a number only if it's a string that can be parsed into a number
    // const normalizedKey =
    //   typeof key === "string" && !Number.isNaN(Number(key)) ? Number(key) : key;

    const isSelected = Object.hasOwn(selectedValues, key.toString());
    const newSelected = { ...selectedValues };

    if (isSelected) {
      delete newSelected[key];
    } else {
      // Ensure null values are handled
      newSelected[key] = list[key] || null;
    }

    onItemSelect(newSelected);
  };

  // Handle "Select All" option
  const handleSelectAll = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();

    const allKeys = Object.keys(list);
    const isAllSelected = Object.keys(selectedValues).length === allKeys.length;

    if (isAllSelected) {
      onItemSelect({}); // Deselect all
    } else {
      const allSelected = allKeys.reduce<Record<number | string, Item | null>>(
        (acc, key) => {
          acc[key] = list[key];
          return acc;
        },
        {}
      );
      onItemSelect(allSelected); // Select all
    }
  };

  // Toggle menu visibility
  const handleMenuToggle = () => setMenuIsOpen((prev) => !prev);

  const handleMenuClose = () => setMenuIsOpen(false);

  // Show selected item names in the MenuButton
  const renderButtonLabel = () => {
    const selectedTitles = Object.keys(selectedValues)
      .map((key) => {
        // Convert the key to a number if needed
        const normalizedKey =
          typeof key === "string" && !Number.isNaN(Number(key))
            ? Number(key)
            : key;

        return list[normalizedKey]?.type || String(key); // Fallback to key for null items
      })
      .filter(Boolean)
      .join(", ");

    return selectedTitles.length > 0 ? selectedTitles : title;
  };

  return (
    <Menu isOpen={menuIsOpen} onClose={handleMenuClose}>
      <MenuButton
        overflow="hidden"
        whiteSpace="normal"
        as={Button}
        isDisabled={isDisabled}
        leftIcon={<MdAddCircle />}
        variant="add"
        onClick={handleMenuToggle}
      >
        {renderButtonLabel()}
      </MenuButton>
      <MenuList
        px={2}
        maxHeight={300}
        overflowY="auto"
        sx={{
          "&::-webkit-scrollbar": {
            width: "10px"
          },
          "&::-webkit-scrollbar-track": {
            background: "white"
          },
          "&::-webkit-scrollbar-thumb": {
            background: "gray",
            borderRadius: "8px"
          }
        }}
      >
        <Input
          autoFocus
          placeholder="Search..."
          variant="filled"
          onChange={(e) => setSearchTerm(e.target.value)}
          my={2}
        />

        {/* Render the "Select All" option at the top */}
        <MenuItem onClick={handleSelectAll} closeOnSelect={false}>
          <Checkbox
            isChecked={
              Object.keys(selectedValues).length === normalizedList.length
            }
          >
            Select All
          </Checkbox>
        </MenuItem>

        {Object.keys(groupedItems).length > 0 ? (
          Object.keys(groupedItems).map((category) => {
            const items = groupedItems[category as keyof typeof groupedItems];

            return (
              <Box key={category} mt={2}>
                {shouldCategorize && (
                  <Text fontWeight="bold" mt={2} mb={1}>
                    {category}
                  </Text>
                )}

                {items.map(([key, value]) => (
                  <MenuItem
                    key={key}
                    onClick={(e) => handleItemClick(key as string, e)}
                    closeOnSelect={false}
                  >
                    <Checkbox isChecked={key in selectedValues}>
                      {withToolTip ? (
                        <Tooltip label={value?.type ?? ""} key={value?.type}>
                          {value ? getShortString(value?.type ?? "", 30) : key}
                        </Tooltip>
                      ) : (
                        <p>{value ? value.type : key}</p>
                      )}
                    </Checkbox>
                  </MenuItem>
                ))}
              </Box>
            );
          })
        ) : (
          <Box p={2}>
            <Text>No items available</Text>
          </Box>
        )}
      </MenuList>
    </Menu>
  );
};
