import { Quill } from "react-quill";

const Inline = Quill.import("blots/inline");

export class CustomHighlight extends Inline {
  static create() {
    const node = super.create();
    node.setAttribute("class", "custom-highlight");
    return node;
  }
}

CustomHighlight.blotName = "customHighlight";
CustomHighlight.tagName = "mark";
CustomHighlight.className = "custom-highlight";
