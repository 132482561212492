import { Box, Flex, Spacer, Text } from "@chakra-ui/react";
import { useEffect, useMemo, useState } from "react";
import { Link as LinkDom, useLocation } from "react-router-dom";
import { auth } from "../api/firebaseApi";
import { firmConfiguration } from "../constans";
import { getSubdomain, setupIntercom } from "../helpers/helpers";
import { useGetUserDetails } from "../hooks/useGetUserDetails";
import styles from "../styles/header.module.css";
import AdminUpdatesModal from "./common/AdminUpdatesModal";
import ExtractionStatusDialog from "./common/ExtractionStatusModal";
import { NavBarLogo } from "./NavbarLogo";
import { UserMenu } from "./UserMenu";

interface HeaderProps {
  link?: string;
}

export const Header = ({ link }: HeaderProps) => {
  const location = useLocation();

  const {
    userDetails: { id, userType, email }
  } = useGetUserDetails();

  const isIndividual = userType === "Individual";

  const [isAuthChecked, setIsAuthChecked] = useState(false);

  useEffect(() => {
    setupIntercom(email, id);
  }, []);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user && location.pathname !== "/login") setIsAuthChecked(true);
    });

    return () => unsubscribe();
  }, []);

  const logoLink = useMemo(() => {
    if (link) {
      return link;
    }
    if (!isIndividual) {
      return "/cases";
    }

    if (isIndividual) {
      return "/";
    }
    return "/";
  }, [auth, id]);

  const subdomain = getSubdomain();
  const firmConfig = firmConfiguration[subdomain];
  return (
    <header className={styles.header} style={firmConfig?.cssForHeader}>
      <Flex alignItems="center">
        <Flex alignItems="center" ml="15px">
          <Text fontWeight={500}>
            <LinkDom to={logoLink} style={{ margin: "0", padding: "0" }} />
          </Text>
          <Box>
            <LinkDom
              to={firmConfig?.domainUrl || logoLink}
              target={firmConfig?.domainUrl ? "_blank" : "_self"}
              style={{
                display: "flex",
                alignItems: "center",
                margin: "0",
                padding: "0"
              }}
            >
              <NavBarLogo />{" "}
            </LinkDom>
          </Box>
        </Flex>
        <Spacer />

        <Flex alignItems="center" px={4}>
          {isAuthChecked && (
            <Flex gap={4} className="max-h-10">
              {userType !== "Individual" && <AdminUpdatesModal />}
              <ExtractionStatusDialog />
              <UserMenu />
            </Flex>
          )}
        </Flex>
      </Flex>
    </header>
  );
};
