import { Link, Text, Tooltip } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { Link as ReactRouterLink, useLocation } from "react-router-dom";
import { sidebarSelectors } from "../../redux/sidebar/selectors";
import { CustomIcon } from "../common/Icons";
import { SidebarLink } from "./types";

type Props = {
  link: SidebarLink;
  onClick?: () => void;
  isSub?: boolean;
};
export const NavigationLink = ({ link, onClick, isSub }: Props) => {
  const location = useLocation();
  const { opened } = useSelector(sidebarSelectors.selectAll);

  const isActive = location.pathname === link.url;
  return (
    <Link
      textAlign="left"
      as={ReactRouterLink}
      to={link.url}
      color={link.defaultActive || isActive ? "#00008b" : "gray.600"}
      fontWeight={600}
      onClick={onClick}
      bg={link.defaultActive || isActive ? "#DFF1FB" : "unset"}
      py={2}
      px={2}
      borderRadius={4}
      width={opened ? "200px" : "auto"}
      transition="width 0.5s ease-in-out"
      display="inline-block"
    >
      <span
        style={{
          display: "inline-block",
          width: opened ? "200px" : "unset",
          paddingLeft: isSub ? "16px" : 0
        }}
      >
        <CustomIcon
          fill={isActive ? "#00008b" : "gray.600"}
          label={link.icon ?? ""}
          width="24px"
          height="24px"
        />
        <span
          className="ml-2"
          style={{
            width: opened ? 100 : 0,
            display: opened ? "unset" : "none",
            transition: "display 1s ease-in-out, opacity 1s ease-in-out"
          }}
        >
          {link.label}
        </span>
      </span>
    </Link>
  );
};
